$primary: #0e4d92;
$primary-shade: #5b9ce1;
$secondary: #f1f1f1;
$myshaddow: 5px 2px 12px 1px rgba(0, 0, 0, 0.3);
$button: #24c7f6;

.App {
  text-align: center;

  .ss2 {
    display: none;
  }
  .ccc {
    display: none;
  }

  .page-title {
    h1 {
      position: absolute;
      top: 1.9%;
      font-size: 27px;
      color: $primary;
      padding-left: 25px;
      font-weight: bold;
    }
  }

  .row {
    margin-right: 0;
  }

  .myButton {
    background-color: $button;
    color: $secondary;
    border-radius: 3px;
    border: unset;
    padding: 9px 30px;
    margin-bottom: 5px;
    box-shadow: 5px 5px 5px #e6e3e3;
    position: absolute;
    right: 10%;
  }

  .app-header {
    padding: 0;
    padding-left: 2px;
    position: inherit;
    .content_close {
      .cases_d {
        .post_message {
          .message-form {
            width: 92%;
            button {
              right: 10%;
            }
          }
        }
      }
    }
  }

  .mycontent {
    // min-height: calc(1vw + 713px);
    margin-top: 40px;
  }

  .modal-button {
    background-color: transparent;
    border: unset;
    border-bottom: $primary-shade 3px solid;
    margin-right: 10px;
    color: $primary-shade;
    padding: 0px 10px 10px 0px;
    .count {
      background-color: red;
      color: $secondary;
      border-radius: 65px;
      padding: 5px 10px;
      margin-left: 10px;
      font-weight: 700;
    }
    &:focus {
      outline: none;
    }
  }

  .active-modal {
    color: $primary !important;
    font-weight: 600;
    border-bottom: $primary 4px solid !important;
  }

  // Dashboard - paginate

  .paginationBttns {
    width: 80%;
    height: 40px;
    list-style: none;
    display: flex;
    justify-content: center;
  }

  .paginationBttns {
    a {
      padding: 10px;
      margin: 8px;
      border-radius: 5px;
      border: 1px solid $primary;
      color: $primary;
      cursor: pointer;
    }
  }

  .paginationBttns {
    a {
      &:hover {
        color: white;
        background-color: $primary;
      }
    }
  }

  .paginationActive {
    a {
      color: white;
      background-color: $primary;
    }
  }

  .paginationDisabled {
    a {
      display: none;
    }
  }

  .cases {
    padding-left: 3rem !important;
    width: 100%;
    .new-case {
      width: 30%;
      border-radius: 12px;
      background-color: $primary-shade;
      color: $secondary;
      padding: 25px;
      padding-left: 40px;
      float: left;
      margin-right: 10px;
      margin-bottom: 10px;
      button {
        margin-right: 5px;
        background-color: $primary;
        border: unset;
        color: $secondary;
        border-radius: 10px;
        padding: 5px 10px;
        font-weight: 600;
        transition: 0.3s ease-in-out;
      }
      &:hover {
        box-shadow: 5px 2px 12px 1px rgba(0, 0, 0, 0.3);
      }
      .actions {
        transition: 0.3s ease-in-out;
        margin-top: 10px;
        .publish:hover {
          color: green;
          padding: 5px 15px;
        }
        .translate:hover {
          color: yellowgreen;
          padding: 5px 15px;
        }
        .delete:hover {
          color: red;
          padding: 5px 15px;
        }
      }
    }
  }

  // new-case
  .reports-dashboard {
    width: 100%;
    .new-case {
      width: 30%;
      border-radius: 12px;
      background-color: $primary-shade;
      color: $secondary;
      padding: 25px;
      padding-left: 40px;
      float: left;
      margin-right: 10px;
      margin-bottom: 10px;
      button {
        margin-right: 5px;
        background-color: $primary;
        border: unset;
        color: $secondary;
        border-radius: 10px;
        padding: 5px 10px;
        font-weight: 600;
        transition: 0.3s ease-in-out;
      }
      &:hover {
        box-shadow: 5px 2px 12px 1px rgba(0, 0, 0, 0.3);
      }
      // .case {
      // }
      // .type {
      // }
      // .municipality {
      // }
      .actions {
        transition: 0.3s ease-in-out;
        margin-top: 10px;
        .publish:hover {
          color: green;
          padding: 5px 15px;
        }
        .translate:hover {
          color: yellowgreen;
          padding: 5px 15px;
        }
        .delete:hover {
          color: red;
          padding: 5px 15px;
        }
      }
    }
  }

  // search
  .search {
    padding-left: 1rem;
    height: calc(1.5em + 0.75rem + 2px);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    margin-bottom: 5px;
  }

  // search - form
  .search-form {
    display: inline-flex;
    align-items: center;

    select {
      height: calc(1.5em + 0.75rem + 2px);
      padding: 0.375rem 0.75rem;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
      color: #495057;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #ced4da;
      border-radius: 0.25rem;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      margin-right: 10px;
    }
    input {
      width: auto;
    }
  }

  // listimi-head
  .listimi-head {
    width: 100%;
    // background-color:  $primary;
    // color: $secondary;
    background-color: #cee1f2;
    color: #000;
    // margin-bottom: 5px;
    // padding: 10px;
    padding: 12.5px 12.5px 12.5px 30px;
    // border-radius: 10px 10px 0 0;
    font-weight: 700;
    margin-top: 15px;

    .status {
      text-align: center;
    }
  }

  // listimi
  .listimi {
    transition: 0.3s ease-in-out;
    width: 100%;
    // background-color:  $primary;
    // color: $secondary;
    background-color: #fff;
    color: #000;
    border-bottom: 1px solid lightgrey;
    // margin-bottom: 5px;
    // padding: 10px;
    padding: 15px 15px 15px 30px;
    // border-radius: 5px;
    @media only screen and (max-width: 780px) {
      width: 100% !important;
    }
    border-radius: unset !important;
    .delete {
      text-align: end;
      padding-right: 0;
      a {
        color: #f5353f;
        background-color: #f5eaeb;
        padding: 7px 9px 7px 9px;
        border-radius: 3px;
      }
    }
    .edit a {
      color: #3dd9a1;
      background-color: #d6fbef;
      padding: 7px 7px 7px 9px;
      border-radius: 3px;
    }
    a {
      color: $secondary;
      &:hover {
        text-decoration: none;
      }
    }
    .text-overflow {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
  .listimi:hover {
    padding-left: 10px;
    background-color: $primary-shade;
    border-left: 15px solid $primary;
    margin-bottom: 10px;
    margin-top: 10px;
    color: black;
    font-weight: 700;
    .fa-edit {
      color: orange;
    }
    .fa-trash {
      color: orangered;
    }
  }

  // form
  // form {
  //   width: 50%;

  //   select {
  //     width: 100%;
  //     padding: 10px;
  //     margin-bottom: 5px;
  //     border-radius: 5px;
  //   }
  //   input {
  //     width: 100%;
  //     padding: 10px;
  //     margin-bottom: 5px;
  //     border-radius: 5px;
  //   }
  //   button {
  //     width: 50%;
  //     padding: 10px;
  //     margin-bottom: 5px;
  //     border-radius: 5px;
  //   }
  // }
  form {
    width: 50%;
    select {
      width: 100%;
      padding: 10px;
      margin-bottom: 5px;
      border-radius: 5px;
    }
    .lang-btn {
      text-decoration: unset;
      color: $primary-shade;
      padding: 5px 10px;
      border-bottom: $primary-shade 4px solid;
      margin-right: 10px;
      cursor: pointer;
    }
    .lang-btn-active {
      text-decoration: unset;
      color: $primary;
      padding: 5px 10px;
      border-bottom: $primary 4px solid;
      margin-right: 10px;
      cursor: pointer;
    }
    input {
      width: 100%;
      padding: 10px;
      margin-bottom: 5px;
      border-radius: 5px;
    }
    textarea {
      width: 100%;
    }
    button {
      width: 50%;
      padding: 10px;
      margin-bottom: 5px;
      border-radius: 5px;
      margin-top: 5px;
    }
  }
  form {
    padding-left: 1.5rem !important;
    @media only screen and (max-width: 780px) {
      width: 100% !important;
    }
    input,
    select,
    textarea {
      border-color: $primary-shade;
      border-style: solid;
      &:focus {
        border-color: $primary;
        border-style: solid;
        border-left: 15px solid $primary;
      }
    }
    button {
      background-color: $primary;
      border: unset;
      color: $secondary;
    }
  }
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@media only screen and (max-width: 1300px) {
  .App {
    #header1 {
      width: 10% !important;
    }
    .res_header {
      padding-left: 32px !important;
    }
    .myButton {
      padding: 9px 15px;
    }
    .app-header {
      padding-left: 6px;
    }
  }
}

@media only screen and (max-width: 768px) {
  .App {
    // CASE-BOX
    .reports-dashboard .new-case {
      width: 47%;
      .case,
      .type,
      .municipality,
      .agency,
      .contact {
        font-size: 15px;
      }
    }
    #header {
      .pro-sidebar {
        .vv2 {
          padding-left: 4px;
        }
        .pro-menu .pro-menu-item {
          font-size: 12.5px;
        }
      }
      .pro-sidebar-footer {
        padding-left: 19px;
      }
      .pro-sidebar-inner .pro-sidebar-layout .pro-sidebar-header .logotext img {
        width: 170px;
      }
    }
    #header1 {
      .pro-sidebar {
        .vv {
          padding-left: 10px;
        }
        .pro-sidebar-footer {
          padding-left: 10px;
        }
      }
    }

    .listimi-head {
      font-size: 12.7px;
    }
    .listimi {
      font-size: 12.7px;
    }
    .search-form select {
      font-size: 12.7px;
    }
    .search-form input {
      font-size: 12.7px;
    }
    // PAGINATION
    .paginationBttns {
      width: 100%;
      font-size: 12.7px;
    }
    // FORM
    form {
      width: 90% !important;
      font-size: 14px;
    }

    .myButton {
      padding: 9px 15px;
      font-size: 12.7px;
    }
    .app-header {
      padding-left: 9px;
    }
    .active-modal {
      font-size: 14px;
    }
    .modal-button {
      font-size: 14px;
    }
    .ss2 {
      display: block;
    }
    .page-title h1 {
      top: 2.9%;
      font-size: 20px;
    }
    // .page-title h1 {
    //   top: 7.9%;
    // }
    // .res_header2 {
    //   flex: 0 0 100%;
    //   max-width: 100%;
    //   .header-profile .page-title p {
    //     top: 7.9%;
    //   }

    //   .ccc {
    //     display: block;
    //   }
    // }
    // .res_header3 {
    //   display: none;
    // }
    // .app-header {
    //   flex: 0 0 100%;
    //   max-width: 100%;
    // }

    // .ss {
    //   display: none;
    // }

    // .ss {
    //   #header {
    //     width: 32%;
    //   }
    // }
  }
}
@media only screen and (max-width: 767px) {
  .App {
    // #header {
    //   width: 26%;
    // }
    .res_header {
      margin-left: 7% !important;
    }
  }
}

@media only screen and (max-width: 590px) {
  .App {
    #header1 {
      display: none;
    }
    .res_header {
      margin-left: 0 !important;
      padding-left: 0 !important;
    }
    .ccc {
      display: block;
    }
    .page-title {
      h1 {
        top: 3%;
      }
      p {
        right: 11% !important;
      }
    }
    .res_header2 {
      flex: 0 0 100%;
      max-width: 100%;
      .header-profile .page-title p {
        top: 3%;
      }
    }
    .res_header3 {
      display: none;
    }
    .app-header {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }

  .reports-dashboard .new-case {
    width: 75% !important;
    margin-left: 30px;
    .case,
    .type,
    .municipality,
    .agency,
    .contact {
      font-size: 12px !important;
    }
  }
  .modal-button {
    font-size: 11px !important;
    margin-left: 20px;
  }
  .page-title h1 {
    margin-left: 23px;
    font-size: 17px !important;
  }
  .mycontent {
    margin-top: 10px !important;
  }
  .cases .new-case {
    width: 90% !important;
    .case,
    .type,
    .municipality,
    .agency,
    .contact {
      font-size: 12px !important;
    }
    button {
      font-size: 12px;
    }
  }
  .search-form select {
    font-size: 9.7px !important;
  }
  .search-form input {
    font-size: 9.7px !important;
  }
}
