.header-profile {
  .page-title {
    padding-bottom: 30px;
    display: block;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2);
    height: 65px;
    padding-top: 7px;
    // vertical-align: middle;
    // padding: 20px 35px;
    // margin-bottom: 30px;
    // margin-left: -15px;
    .profile,
    p,
    h1 {
      display: inline-block;
    }
    p {
      position: absolute;
      right: 5.5%;
      // top: 3%;
      top: 1.9%;
    }
    .description {
      margin-top: 19px;
      font-size: 12.5px;
      right: 5%;
    }
    .profile {
      // display: inline;
      // background-color: #5b9ce1;
      border-radius: 50%;
      height: 50px;
      width: 50px;
      float: right;
      // top: 2.5%;
      right: 5%;
      position: absolute;
      background-size: contain;
      background-position: center;
      a img {
        border-radius: 50%;
        height: 50px;
        width: 50px;
        border: 1px solid #5b9ce1;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
        object-fit: cover;
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .header-profile {
    .page-title {
      padding-top: 13px;
      p {
        position: absolute;
        right: 12%;
        // top: 3%;
        font-size: 15px;
      }
      .description {
        margin-top: 17px;
        font-size: 12.5px;
        right: 11.5%;
      }
      .profile {
        margin-top: -5px;
        right: 3%;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .header-profile .page-title {
    p {
      right: 7%;
      font-size: 12.9px;
    }
    .description {
      margin-top: 17px;
      font-size: 11.5px;
      right: 5.5%;
    }
  }
}

@media only screen and (max-width: 590px) {
  .header-profile .page-title {
    p {
      right: 15% !important;
      font-size: 12px;
      top: 2.5%;
    }
    .description {
      margin-top: 17px;
      font-size: 10px;
      right: 5.5%;
    }
  }
}
