#header1 {
  width: 7.6%;
  height: 100vh;
  position: fixed;
  .pro-sidebar {
    height: 100vh;
  }
  .pro-sidebar.collapsed {
    width: auto !important;
    min-width: auto !important;
  }
  .pro-sidebar
    .pro-menu.shaped
    .pro-menu-item
    > .pro-inner-item
    > .pro-icon-wrapper {
    background-color: transparent;
  }
  .closemenu {
    color: #000;
    position: absolute;
    color: #f2c438;
    //   position: fixed;
    right: 0;
    // left: 95%;
    z-index: 9999;
    line-height: 20px;
    border-radius: 50%;
    font-weight: bold;
    font-size: 36px;
    top: 71px;
    cursor: pointer;
  }
  .pro-sidebar {
    width: 100%;
    min-width: 100%;

    .vv {
      // padding-left: 0px !important;
      padding-left: 40px;
    }
    ul {
      .pro-sub-menu .pro-inner-list-item {
        background-color: transparent;
        margin-left: 40px;
        margin-top: -15px;
      }
      .submenu {
        padding: 0 !important;
      }
    }
    .pro-sidebar-content {
      nav {
        .vv {
          padding-left: 30px;
        }
      }
    }
    .pro-sidebar-footer {
      padding-left: 40px;
    }
  }
  .pro-sidebar.collapsed {
    // width: 80px;
    // min-width: 80px;
    min-width: 55px;
  }
  .pro-sidebar-inner {
    // background-color: white;
    background-color: #0e4d92;
    color: #fff;
    // box-shadow: 0.5px 0.866px 2px 0px rgba(0, 0, 0, 0.15);
  }
  .pro-sidebar-inner {
    .pro-sidebar-layout {
      overflow-y: scroll;

      .pro-sidebar-header {
        border: none;
        margin: auto;
        padding: 70px 0 100px 0;
        .logotext img {
          width: 200px;
        }
        .logotext2 img {
          // width: 290px;
          // padding-left: 55px;
          // margin-top: 50px;
          display: none;
        }
      }
    }

    ul {
      // padding: 0 5px;

      .pro-inner-item {
        color: #fff;
        margin: 3px 0px;
        // font-weight: bold;

        .pro-icon-wrapper {
          // background-color: #fbf4cd;
          color: #fff;
          // border-radius: 3px;
          font-size: 20px;

          .pro-item-content {
            color: #fff;
          }
        }
      }
      .pro-inner-list-item.popper-element {
        z-index: 999;
      }
    }
    .active {
      // background-image: linear-gradient(0deg, #fece00 0%, #ffe172 100%);
      background-color: #fff;
      border-radius: 20px 0 0 20px;
      .pro-inner-item {
        color: #000;
        font-weight: bold;
        .pro-icon-wrapper {
          color: #000;
        }
      }
    }
  }

  .logo {
    padding: 20px;
  }
}
#header {
  // position: inherit;
  // width: 220px;
  // width: 25%;
  width: 24.2%;
  height: 100vh;
  position: fixed;

  .pro-sidebar {
    height: 100vh;
  }
  .pro-sidebar.collapsed {
    width: auto !important;
    min-width: auto !important;
  }
  .pro-sidebar
    .pro-menu.shaped
    .pro-menu-item
    > .pro-inner-item
    > .pro-icon-wrapper {
    background-color: transparent;
  }
  .closemenu {
    color: #000;
    position: absolute;
    color: #f2c438;
    //   position: fixed;
    right: 0;
    // left: 95%;
    z-index: 9999;
    line-height: 20px;
    border-radius: 50%;
    font-weight: bold;
    font-size: 36px;
    top: 71px;
    cursor: pointer;
  }
  .pro-sidebar {
    width: 100%;
    min-width: 100%;
    .vv2 {
      // padding-left: 40px;
      padding-left: 70px;
      .vv {
        padding-left: 0px !important;
      }
      ul {
        .pro-sub-menu .pro-inner-list-item {
          background-color: transparent;
          margin-left: 40px;
          margin-top: -15px;
        }
        .submenu {
          padding: 0 !important;
        }
      }
    }
  }
  .pro-sidebar.collapsed {
    // width: 80px;
    // min-width: 80px;
    min-width: 55px;
  }
  .pro-sidebar-inner {
    // background-color: white;
    background-color: #0e4d92;
    color: #fff;
    // box-shadow: 0.5px 0.866px 2px 0px rgba(0, 0, 0, 0.15);
  }
  .pro-sidebar-inner {
    .pro-sidebar-layout {
      overflow-y: scroll;

      .pro-sidebar-header {
        border: none;
        margin: auto;
        padding: 70px 0 20px 0;
        .logotext img {
          width: 200px;
        }
        .logotext2 img {
          width: 230px;
          padding-left: 25px;
          margin-top: 40px;
        }
      }
    }

    ul {
      // padding: 0 5px;

      .pro-inner-item {
        color: #fff;
        margin: 3px 0px;
        // font-weight: bold;

        .pro-icon-wrapper {
          // background-color: #fbf4cd;
          color: #fff;
          // border-radius: 3px;
          font-size: 20px;

          .pro-item-content {
            color: #fff;
          }
        }
      }
    }
    .active {
      // background-image: linear-gradient(0deg, #fece00 0%, #ffe172 100%);
      background-color: #fff;
      border-radius: 20px 0 0 20px;
      .pro-inner-item {
        color: #000;
        font-weight: bold;
        .pro-icon-wrapper {
          color: #000;
        }
      }
      .active1,
      .active2,
      .active3,
      .active4,
      .active5,
      .active6 {
        background-color: transparent;
        border-radius: 20px 0 0 20px;
        .pro-inner-item {
          color: #fff;
          font-weight: bold;
          .pro-icon-wrapper {
            color: #fff;
          }
        }
      }
    }
  }
  .pro-sidebar-footer {
    padding-left: 60px;
  }
  .logo {
    padding: 20px;
  }
}

@media only screen and (max-width: 1024px) {
  #header {
    .pro-sidebar {
      width: 100%;
      min-width: 100%;
      .vv2 {
        padding-left: 20px;
      }
    }
  }
}

@media only screen and (max-width: 720px) {
  html {
    overflow: hidden;
  }
}
