$primary: #0e4d92;
$primary-shade: #5b9ce1;
$secondary: #f1f1f1;
$myshaddow: 5px 2px 12px 1px rgba(0, 0, 0, 0.3);
.show-case {
  //   padding: 0rem 0 3rem 0 !important;
  .grid {
    // margin-left: 60px;
    // padding-left: 40px;
    padding: 0px 0px 0px 79px;
    font-size: 30px;
    cursor: pointer;
    .modal-button {
      padding: 0px 0px 10px 0px !important;
      margin-right: 20px;
    }
  }
  .cases {
    .reports-dashboard {
      .case-discussion {
        .page-title {
          h1 {
            padding-left: 0 !important;
          }
        }
      }
    }
    .reported_cases {
      .page-title {
        h1 {
          padding-left: 0 !important;
        }
      }
    }
  }

  .myButton {
    background-color: $primary;
    color: $secondary;
    border-radius: 5px;
    border: unset;
    padding: 10px;
    margin-bottom: 5px;
  }
}

@media only screen and (max-width: 768px) {
  .show-case {
    .grid .modal-button {
      font-size: 23px;
      margin-right: 7px;
      margin-bottom: -20px;
    }
  }
}

@media only screen and (max-width: 590px) {
  .show-case {
    .grid .modal-button {
      font-size: 23px !important;
      margin-right: 0px;
      margin-bottom: -20px;
    }
  }
}
