$primary: #0e4d92;
$primary-shade: #5b9ce1;
$secondary: #f1f1f1;
$myshaddow: 5px 2px 12px 1px rgba(0, 0, 0, 0.3);
.profile {
  padding: 0rem 0 3rem 0 !important;
  .profile-content {
    padding: 0 30px 30px 30px;
    .user_data {
      p {
        font-size: 25px;
        font-weight: 500;
        color: $primary;
      }
    }
  }
  .user__ {
    text-align: center;
    button {
      background-color: $primary;
      border: unset;
      color: $secondary;
      width: 100%;
      padding: 10px;
      margin: 10px 0;
      border-radius: 5px;
      float: left;
    }
    .change_pass {
      width: 48.3% !important;
      margin-left: 20px;
      button {
      }
    }
  }
  .page-title {
    h1 {
      padding-top: 10px;
    }
  }
  // .profile-form {
  //   margin-top: 10px;
  //   form {
  //     width: 70% !important;
  //   }
  // }
}
